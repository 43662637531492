// src/components/ClassesSection.tsx
import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

interface ClassItem {
  title: string;
  description: string;
  image: string;
}

const classes: ClassItem[] = [
  {
    title: "Beginner Yoga",
    description: "Perfect for those new to yoga.",
    image:
      "https://images.ravpages.co.il/xsite_resources/user_content/4f/bf/f5/c5/4fbff5c528befac2328f392c6d9b44b9/images/9f63c05dd61c736a2cac4fd7e80a4a2f_282X423.JPG?cc=8&convert=webp&ver=3.12&rxc=1705555702",
  },
  {
    title: "Intermediate Yoga",
    description: "Perfect for those how love to do yoga.",
    image:
      "https://images.ravpages.co.il/xsite_resources/user_content/4f/bf/f5/c5/4fbff5c528befac2328f392c6d9b44b9/images/6d018416bf8ed8b6b23e7020f41f25ac_306X204.JPG?cc=8&convert=webp&ver=3.12&rxc=1705555702",
  },
  {
    title: "Expert Yoga",
    description: "Me Hamelech Sheyachole",
    image:
      "https://images.ravpages.co.il/xsite_resources/user_content/4f/bf/f5/c5/4fbff5c528befac2328f392c6d9b44b9/images/c1a63b8dfa30a1479c25b78047cf1925_281X422.JPG?cc=8&convert=webp&ver=3.12&rxc=1705555702",
  },
  // Add more classes as needed
];
const ClassesSection: React.FC = () => {
  return (
    <Container sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom align="center">
        Our Classes
      </Typography>
      <Grid container spacing={4}>
        {classes.map((classItem, index) => (
          <Grid item key={index} xs={12} md={4}>
            <Card
              sx={{
                position: "relative",
                overflow: "hidden",
                borderRadius: "16px",
                boxShadow: 3,
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "scale(1.03)",
                  boxShadow: 6,
                },
              }}
            >
              <CardActionArea>
                <CardMedia component="img" height="200" image={classItem.image} alt={classItem.title} />
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    bgcolor: "rgba(0, 0, 0, 0.6)",
                    color: "white",
                    opacity: 0,
                    transition: "opacity 0.3s",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    p: 2,
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                >
                  <Typography variant="h5">{classItem.title}</Typography>
                  <Typography variant="body2">{classItem.description}</Typography>
                </Box>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ClassesSection;
