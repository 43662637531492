// src/components/Footer.tsx
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Footer: React.FC = () => {
  return (
    <Box sx={{ backgroundColor: "#f5f5f5", p: 3, mt: 5 }}>
      <Typography variant="body2" color="textSecondary" align="center">
        © {new Date().getFullYear()} Yoga with Shelly
      </Typography>
    </Box>
  );
};

export default Footer;
