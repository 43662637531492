import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Backdrop } from "@mui/material";
import { IconWrapper, navItems, StyledLink, StyledList, StyledListItem } from "./SideBar.tsx";
import { SidebarText } from "./SideBarText.tsx";
import { SidebarProps } from "./MobileHeader.tsx";
import { useAppStore } from "../stores/AppStore.tsx";

const SidebarDrawer = styled(Box)(({ theme }) => ({
  paddingTop: "50px",
  position: "fixed",
  top: 0,
  right: 0,
  height: "100vh",
  backgroundColor: theme.palette.background.default,
  width: "30%", // Sidebar covers 30% of the screen
  maxWidth: "400px", // Optional: limit the max width
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)", // Optional shadow for the drawer
  zIndex: theme.zIndex.drawer,
  overflowX: "hidden",
  transition: "transform 0.3s ease-in-out",
  transform: "translateX(100%)", // Initially hidden
}));
const shouldOpenSidebar = (isMobile: boolean, isOpen: boolean) => {
  return isMobile ? isOpen : false;
};

// Sidebar content
const SidebarContent = styled(Box)(({ theme }) => ({
  height: "100%",
  padding: theme.spacing(2),
  overflowY: "auto",
}));
const handleMenuClick = (event: React.MouseEvent) => {
  event.stopPropagation();
};
const NavigationBarMobile = ({ isOpen, setIsOpen }: SidebarProps) => {
  const appStore = useAppStore();
  // Toggle sidebar visibility
  const toggleSidebar = (open: boolean) => () => {
    setIsOpen(open);
  };

  useEffect(() => {
    if (shouldOpenSidebar(appStore.isMobile, isOpen)) {
      document.body.style.overflow = "hidden"; // Disable scroll
    } else {
      document.body.style.overflow = ""; // Re-enable scroll
    }

    return () => {
      document.body.style.overflow = ""; // Clean up on unmount
    };
  }, [appStore.isMobile, isOpen]);

  return (
    <>
      {shouldOpenSidebar(appStore.isMobile, isOpen) && (
        <>
          <SidebarDrawer style={{ transform: isOpen ? "translateX(0)" : "translateX(100%)" }}>
            <SidebarContent onClick={handleMenuClick}>
              <StyledList>
                {navItems.map((item) => (
                  <StyledLink to={item.path} key={item.text}>
                    <StyledListItem>
                      <IconWrapper>
                        {item.icon}
                        <SidebarText variant="body2">{item.text}</SidebarText>
                      </IconWrapper>
                    </StyledListItem>
                  </StyledLink>
                ))}
              </StyledList>
            </SidebarContent>
          </SidebarDrawer>

          <Backdrop
            open={shouldOpenSidebar(appStore.isMobile, isOpen)}
            onClick={toggleSidebar(false)} // Close sidebar on backdrop click
            sx={{
              zIndex: (theme) => theme.zIndex.drawer - 1,
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent shadow
            }}
          />
        </>
      )}
    </>
  );
};

export default NavigationBarMobile;
