import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, IconButton, Drawer, List, ListItem, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

export interface SidebarProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}
// Styled container for the menu button
const MenuButtonContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  // calculate the right position based on the sidebar width and the right padding its 30% width with padding of 2
  right: `calc(15% - 24px)`,
  zIndex: theme.zIndex.drawer + 1,
}));

export const MenuWithSidebar = ({ isOpen, setIsOpen }: SidebarProps) => {
  // Toggle sidebar visibility
  const toggleSidebar = (open: boolean) => () => {
    setIsOpen(!open);
  };

  return (
    <MenuButtonContainer>
      <IconButton onClick={toggleSidebar(isOpen)} aria-label="Open Menu">
        <MenuIcon fontSize="large" />
      </IconButton>
    </MenuButtonContainer>
  );
};
