// src/components/AboutSection.tsx
import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

const AboutSection: React.FC = () => {
  return (
    <Container sx={{ py: 5 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} alignContent="center">
          <img
            src="https://images.ravpages.co.il/xsite_resources/user_content/4f/bf/f5/c5/4fbff5c528befac2328f392c6d9b44b9/images/a5506424796c06bd553a9c637633a44f.JPG?cc=8&convert=webp&maxWidth=1400&rxc=1705555702"
            alt="Shelly"
            width="100%"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            About Shelly
          </Typography>
          <Typography variant="body1">
            Shelly, the passionate founder of Santai Yoga Studio, is a dedicated yoga instructor with a rich background
            in Ashtanga yoga. At 29 years old, she has already made a significant impact in the yoga community. Over the
            past three years, Santai has blossomed under her guidance, growing to serve over 200 clients with the help
            of three talented yoga teachers. Shelly’s journey began with a transformative year of yoga teaching in
            India, where she specialized in Ashtanga yoga. This experience deepened her practice and honed her teaching
            skills, allowing her to bring authentic and profound insights to her students. Recognized as one of the best
            yoga teachers in Herzelia and the surrounding area, Shelly is committed to fostering a welcoming and
            nurturing environment. Her classes emphasize alignment, breath work, and the mind-body connection, catering
            to both beginners and experienced practitioners. Join Shelly at Santai Yoga Studio to embark on a journey of
            self-discovery, balance, and inner peace. Experience firsthand why so many have chosen her as their guide in
            the world of yoga. Feel free to modify or expand upon this text to better suit your website’s tone and
            specific details.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutSection;
