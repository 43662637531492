import React from "react";
import { styled } from "@mui/material/styles";
import { List, ListItem, Box, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import {
  Info as InfoIcon,
  ContactMail as ContactMailIcon,
  CalendarMonthOutlined as CalendarMonthOutlinedIcon,
} from "@mui/icons-material";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import { SidebarText } from "./SideBarText.tsx";
import { useAppStore } from "../stores/AppStore.tsx";

// Dimensions for the sidebar
export const fullSidebarWidth = 120;
export const compactSidebarWidth = 70;

// Navigation items interface
interface NavItem {
  text: string;
  icon: React.ReactElement;
  path: string;
}

// Props for Sidebar
interface SidebarProps {
  isOpen: boolean;
}

const SidebarContainer = styled(Box)(({ theme }) => ({
  height: "100vh",
  position: "fixed",
  top: 0,
  right: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
  transition: "width 0.3s ease-in-out, transform 0.3s ease-in-out",
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
  display: "flex",
  justifyContent: "center",
}));

export const StyledList = styled(List)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  // justifyContent: "space-evenly",
  alignItems: "center",
  padding: 0,
  margin: 0,
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
}));

export const IconWrapper = styled(IconButton)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
  transition: "transform 0.3s ease, color 0.3s ease", // Smooth hover effect
  "&:hover": {
    transform: "scale(1.2)", // Slightly enlarge the icon
    color: theme.palette.primary.main, // Focus color
  },
  fontSize: "1.5rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "2rem",
  },
}));

export const shouldOpenSidebar = (isMobile: boolean, isOpen: boolean) => {
  return isMobile ? isOpen : true;
};

interface SidebarProps {
  isOpen: boolean;
}
export const navItems: NavItem[] = [
  { text: "בית", icon: <SelfImprovementIcon />, path: "/" },
  { text: "עלינו", icon: <InfoIcon />, path: "/about" },
  { text: "לוח תירגולים", icon: <CalendarMonthOutlinedIcon />, path: "/schedule" },
  { text: "צור קשר", icon: <ContactMailIcon />, path: "/contact" },
];

const Sidebar: React.FC<SidebarProps> = ({ isOpen }: SidebarProps) => {
  const appStore = useAppStore();

  return (
    <SidebarContainer
      style={{
        width: isOpen ? fullSidebarWidth : 0,
        transform: isOpen ? "translateX(0)" : "translateX(100%)",
      }}
    >
      {shouldOpenSidebar(appStore.isMobile, false) && (
        <StyledList>
          {navItems.map((item) => (
            <StyledLink to={item.path} key={item.text}>
              <StyledListItem>
                <IconWrapper>
                  {item.icon}
                  <SidebarText variant="body2">{item.text}</SidebarText>
                </IconWrapper>
              </StyledListItem>
            </StyledLink>
          ))}
        </StyledList>
      )}
    </SidebarContainer>
  );
};

export default Sidebar;
