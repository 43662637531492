// src/components/MainContainer.tsx
import React from "react";
import Box from "@mui/material/Box";

interface MainContainerProps {
  children: React.ReactNode;
}

const MainContainer: React.FC<MainContainerProps> = ({ children }) => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        inlineSize: "100%",
        px: { xs: 2, sm: 3, md: 5 }, // Responsive horizontal padding
      }}
    >
      {children}
    </Box>
  );
};

export default MainContainer;
