// src/components/Testimonials.js

import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Container, Card, CardContent, Typography, Avatar, Box, IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useSwipeable } from "react-swipeable";

interface Testimonial {
  name: string;
  testimonial: string;
}

const testimonials: Testimonial[] = [
  {
    name: "Shanny Miller",
    testimonial: "Shelly is an amazing instructor!",
  },
  {
    name: "Guy Miller",
    testimonial: "Shelly is The Best!",
  },
  {
    name: "Jonathan Mocktader AKA Mockta",
    testimonial: "Barda is The Best!",
  },
  // Add more testimonials as needed
];

// Styled Components
const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: theme.palette.background.default,
  position: "relative",
}));

const VerticalCenter = styled(Box)(({ theme }) => ({
  alignItems: "center",
  flexDirection: "row",
  display: "flex",
  width: "100%",
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  textAlign: "center",
  position: "relative",
}));

const StyledTypographyTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

const StyledTypographySubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  margin: "auto",
  maxWidth: "90%",
  padding: theme.spacing(4),
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  borderRadius: "10px",
  transition: "transform 0.3s",
  "&:hover": {
    transform: "translateY(-5px)",
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: "90%",
    padding: theme.spacing(2),
  },
}));

const StyledTestimonial = styled(Typography)(({ theme }) => ({
  fontStyle: "italic",
  color: "#555",
  marginTop: theme.spacing(2),
}));

const NavigationButton = styled(IconButton)(({ theme }) => ({
  display: "flex",
  height: 50,
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
}));

const Indicator = styled(Box)(({ theme }) => ({
  height: 10,
  width: 10,
  bgcolor: "grey.400",
  borderRadius: "50%",
  mx: 0.5,
  cursor: "pointer",
}));

const Testimonials = () => {
  const [current, setCurrent] = useState(0);
  const length = testimonials.length;

  const handlePrev = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const handleNext = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  // Autoplay: Change testimonial every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);
    return () => clearInterval(interval);
  }, [current]);

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") {
        handlePrev();
      } else if (e.key === "ArrowRight") {
        handleNext();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [current]);

  // Swipe handlers
  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
    trackMouse: true,
    trackTouch: true,
  });

  return (
    <StyledBox {...handlers}>
      <StyledContainer>
        <StyledTypographyTitle variant="h4">What Our Clients Say</StyledTypographyTitle>
        <StyledTypographySubtitle variant="subtitle1">
          We pride ourselves on delivering the best service possible. Here's what our clients have to say.
        </StyledTypographySubtitle>
        <VerticalCenter>
          {/* Navigation Buttons */}
          <NavigationButton aria-label="previous testimonial" onClick={handlePrev} sx={{ left: 0 }}>
            <ArrowBackIos />
          </NavigationButton>

          {/* Current Testimonial */}
          {testimonials.map((testimonial, index) => (
            <Box
              key={index}
              sx={{
                display: index === current ? "flex" : "none",
                opacity: index === current ? 1 : 0,
                transition: "opacity 0.5s ease-in-out",
                width: "100%",
                height: "100%",
              }}
            >
              <StyledCard sx={{ width: "100%" }}>
                {/* <StyledAvatar alt={testimonial.name} src={testimonial.image} /> */}
                <Typography variant="h6" gutterBottom>
                  {testimonial.name}
                </Typography>
                {/* <Typography variant="body2" gutterBottom>
                {testimonial.title}
              </Typography> */}
                <CardContent>
                  <StyledTestimonial variant="body1">"{testimonial.testimonial}"</StyledTestimonial>
                </CardContent>
              </StyledCard>
            </Box>
          ))}
          <NavigationButton aria-label="next testimonial" onClick={handleNext} sx={{ right: 0 }}>
            <ArrowForwardIos />
          </NavigationButton>
        </VerticalCenter>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          {testimonials.map((_, index) => (
            <Indicator
              key={index}
              sx={{
                bgcolor: index === current ? "primary.main" : "grey.400",
              }}
              onClick={() => setCurrent(index)}
            />
          ))}
        </Box>
      </StyledContainer>
    </StyledBox>
  );
};

export default Testimonials;
