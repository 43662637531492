import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme/theme.ts";
import AboutSection from "../components/AboutSection.tsx";
import ClassesSection from "../components/ClassesSection.tsx";
import TestimonialsSection from "../components/TestimonialsSection.tsx";
import ContactForm from "../components/ContactForm.tsx";
import Footer from "../components/Footer.tsx";
import MainContainer from "../components/MainContainer.tsx";
import AboutSection2 from "../components/AboutSection2.tsx";
// import YouTubeVideo from "../components/videoPlayers/YouTubePlayer.tsx";
import Sidebar, { compactSidebarWidth, fullSidebarWidth } from "../components/SideBar.tsx";
import DraggableMenuController from "../components/DraggableMenuController.tsx";
import { styled, keyframes } from "@mui/material/styles";
// import VimeoPlayer from "react-player/vimeo";
// import VimeoPlayerLocal from "../components/videoPlayers/VimeoPlayer.tsx";
import { VimeoVideo } from "../components/videoPlayers/VimeoPlayer2.tsx";
import { useAppStore } from "../stores/AppStore.tsx";
import { MenuWithSidebar } from "../components/MobileHeader.tsx";
import NavigationBarMobile from "../components/NavigationBarMobile.tsx";
import { margin } from "@mui/system";

// Animation Keyframes
const fadeInSlide = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled container for page layout
const PageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  height: "100vh", // Full height for proper layout
  transition: "margin-right 0.3s ease-in-out",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column", // Stack sidebar and content on smaller screens
  },
}));

const MainContent = styled("div", {
  shouldForwardProp: (prop) => prop !== "isSidebarOpen",
})<{ isSidebarOpen: boolean }>(({ theme, isSidebarOpen }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(2),
  marginRight: isSidebarOpen ? fullSidebarWidth : 0, // Adjust based on sidebar state
  [theme.breakpoints.down("sm")]: {
    marginTop: "35px",
    marginRight: 0,
    flexDirection: "column", // Stack sidebar and content on smaller screens
  },
  animation: `${fadeInSlide} 0.6s ease-out`, // Apply animation
  animationFillMode: "forwards", // Ensure the final state persists
}));

const Santai2: React.FC = () => {
  const appStore = useAppStore();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    setIsSidebarOpen(!appStore.isMobile);
  }, [appStore.isMobile]);

  return (
    <ThemeProvider theme={theme}>
      {/* Main Layout */}
      <PageContainer>
        {appStore.isMobile && <MenuWithSidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />}
        {/* Sidebar */}
        <Sidebar isOpen={isSidebarOpen} />
        <NavigationBarMobile isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />

        {/* Main Content */}
        <MainContent isSidebarOpen={isSidebarOpen}>
          {/* <YouTubeVideo
            url={"https://youtube.com/shorts/LF1dk_AwUcQ?si=WEOQH6BSs7smbtgI"}
            playing={true}
            muted={true}
            controls={false}
            loop={true}
            pointerEvents="none"
          /> */}
          <VimeoVideo
            url={"https://player.vimeo.com/video/1037006934"}
            playing={true}
            muted={true}
            controls={false}
            playBackRate={95}
            loop={true}
            isSidebarOpen={isSidebarOpen}
          />
          <MainContainer>
            <AboutSection />
            <AboutSection2 />
            <ClassesSection />
            <TestimonialsSection />
            <ContactForm />
            <Footer />
          </MainContainer>
        </MainContent>
      </PageContainer>
    </ThemeProvider>
  );
};

export default Santai2;
