import { useMediaQuery, useTheme } from "@mui/material";
import React, { createContext, useContext, useEffect, useState } from "react";

// Define the structure of the store
interface AppState {
  isMobile: boolean; // Property to track if it's a mobile device
  //   setIsMobile: (value: boolean) => void; // Setter function for updating
}

// Create the context with a default value
const AppContext = createContext<AppState | undefined>(undefined);

// Provide the store
export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return <AppContext.Provider value={{ isMobile }}>{children}</AppContext.Provider>;
};

// Custom hook for consuming the store
export const useAppStore = (): AppState => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppStore must be used within an AppProvider");
  }
  return context;
};
