import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Santai2 from "./pages/Santai2.tsx";
import { CssBaseline, ThemeProvider } from "@mui/material";
import "@fontsource/playfair-display"; // Import Playfair Display
import "@fontsource/roboto"; // Import Roboto
import theme from "./theme/theme.ts";
import { AppProvider } from "./stores/AppStore.tsx";

const App = () => (
  <Router>
    <CssBaseline>
      <Routes>
        <Route path="/" Component={Santai2} />
        {/* <Route path="/santai" element={<Santai />} /> */}
        <Route path="/santai2" Component={Santai2} />
      </Routes>
    </CssBaseline>
  </Router>
);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppProvider>
        <App />
      </AppProvider>
    </ThemeProvider>
  </React.StrictMode>
);
