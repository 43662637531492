// src/components/AboutSection.tsx
import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

const AboutSection: React.FC = () => {
  return (
    <Container sx={{ py: 5 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} alignContent="center">
          <img
            src="https://geopelie.com/cdn/shop/articles/posture_de_yoga_du_baton_inverse.jpg?v=1700603036"
            alt="Shelly"
            width="100%"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <div dir="rtl">
            <Typography variant="body2">
              שלי את מדהימה איך אני אוהבת ללמוד אצלך את המורה הכי טובה שיש וגם אחות מדהימה וגם כל הצוות מדהים
            </Typography>
            <Typography variant="body2">מילי אחותך האהובה</Typography>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutSection;
