import React from "react";
import { Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const ResponsiveText = styled(Typography)(({ theme }) => ({
  fontSize: "0.9rem", // Default font size for larger screens
  // [theme.breakpoints.down("sm")]: {
  //   fontSize: "0.6rem", // Smaller font size for small screens
  // },
}));

interface SidebarTextProps extends TypographyProps {
  children: React.ReactNode;
}

export const SidebarText: React.FC<SidebarTextProps> = ({ children, ...props }) => {
  return <ResponsiveText {...props}>{children}</ResponsiveText>;
};
