import React from "react";
import ReactPlayer from "react-player";
import { styled } from "@mui/material/styles";
import { Box, Container } from "@mui/material";

const PlayerWrapper = styled(Box)(() => ({
  position: "relative",
  paddingTop: "80%", // 16:9 Aspect Ratio
  zIndex: 1,
}));

const StyledReactPlayer = styled(ReactPlayer)(({ isSidebarOpen }: { isSidebarOpen: boolean }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover", // Ensures the video covers the entire area
  zIndex: 1,
  pointerEvents: "none",
}));

export interface VimeoVideoProps {
  url: string;
  controls?: boolean;
  playing?: boolean;
  muted?: boolean;
  loop?: boolean;
  width?: string | number;
  pointerEvents?: string;
  height?: string | number;
  playBackRate?: number;
  isSidebarOpen: boolean; // Pass sidebar state to control pointer events
}

export const VimeoVideo = ({
  url,
  width = "100%",
  height = "100%",
  muted = true,
  loop = false,
  playing = true,
  controls = false,
  playBackRate = 1,
  isSidebarOpen,
}: VimeoVideoProps) => (
  <Container sx={{ padding: 4, zIndex: 1 }}>
    <PlayerWrapper>
      <StyledReactPlayer
        url={url}
        playbackRate={playBackRate}
        controls={controls}
        width={width}
        height={height}
        loop={loop}
        muted={muted}
        playing={playing}
        isSidebarOpen={isSidebarOpen}
      />
    </PlayerWrapper>
  </Container>
);
