// src/theme.ts
import { createTheme } from '@mui/material/styles';
import '../assets/fonts/fonts.css'; // Import the font definitions

// Define custom colors
const primaryColor = '#B388EB'; // Soft lavender
const secondaryColor = '#80CBC4'; // Calming teal
const backgroundColor = '#Dfd6cd'; // The background color you provided


const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    background: {
      default: backgroundColor,
    },
    text: {
      primary: '#333333', // Dark gray for readability
      secondary: '#555555',
    },
  },
  typography: {
    fontFamily: 'Vensflok, Arial, sans-serif', // Set Vensflok as the primary font
    h1: {
      lineHeight: 1.2, // Tighten the line height
      fontFamily: 'Vensflok, Arial, sans-serif',
    },
    h2: {
      lineHeight: 1.2,
      fontFamily: 'Vensflok, Arial, sans-serif',
    },
    h3: {
      lineHeight: 1.2,
      fontFamily: 'Ploni, Arial, sans-serif',
      fontWeight: 700, // Bold weight
    },
    // Define other variants as needed
    body1: {
      lineHeight: 1.6, // Increase the line height
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 400, // Normal weight
    },
    body2: {
      lineHeight: 1.6,
      fontFamily: 'Ploni, Arial, sans-serif',
      fontWeight: 400
    },
    // Continue for other Typography variants...
  },
  components: {
    // Customize components if needed
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '20px', // Rounded buttons
          textTransform: 'none', // Preserve case in button text
        },
      },
    },
  },
});

export default theme;


